<template>
  <b-overlay :show="loadingGuests" rounded="sm" variant="white">
    <b-card title="Hostes">
      <!-- GUESTS -->
      <b-row class="mb-1">
        <b-col cols="12">
          <b-alert variant="warning" class="mb-1" :show="guestsNumberInconsistency">
            <div class="alert-body font-weight-normal">
              El nombre d'hostes de la reserva inicial no coincideix amb el
              nombre d'hostes confirmats durant el check-in online.
              <!-- <span class="cursor-pointer font-weight-bolder text-decoration-underline-hover"
                @click="onUpdateAdultsNumberClicked">
                Actualiza el nº d'hostes
              </span> -->
            </div>
          </b-alert>
          <b-list-group v-if="guests.length > 0" flush>
            <b-list-group-item v-for="guest in guests" :key="`guest-${guest.uuid}`"
              class="d-flex justify-content-sm-between cursor-pointer" @click.stop="showGuestButtonClicked(guest)">
              <div class="d-flex align-items-center">
                <span class="mr-2">
                  <feather-icon icon="UserIcon" size="20" />
                </span>
                <div>
                  <h6 class="mb-25">
                    {{ guest.fullName || "Adult" }}
                  </h6>
                  <b-card-text>
                    <b-badge v-for="tag in guestSubtitleTags(guest)" :key="tag.label" :variant="tag.variant" class="mx-25"
                      pill>
                      {{ tag.label }}
                    </b-badge>
                  </b-card-text>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <b-badge v-if="guest.reported" variant="primary" class="mx-25">
                  Reportat
                </b-badge>
                <b-badge v-if="guest.confirmed" variant="success" class="mx-25">
                  Confirmat
                </b-badge>
                <b-badge v-if="!guest.confirmed && !guest.signature" variant="warning" class="mx-25">
                  Pendent de signatura
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-alert variant="warning" class="mb-0" :show="guests.length === 0">
            <div class="alert-body">
              Encara no s'han confirmat hostes per aquesta reserva
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <!-- ADD GUEST BUTTON -->
      <b-row class="mb-2 d-flex justify-content-start">
        <b-col cols="12" sm="auto" v-if="canAddClientAsGuest">
          <b-button variant="primary" size="sm" block @click.stop="addClientAsGuest">
            Afegeix client com a hoste
          </b-button>
        </b-col>
        <b-col cols="12" sm="auto">
          <b-button variant="primary" size="sm" block @click.stop="newGuestButtonClicked">
            Afegeix un hoste
          </b-button>
        </b-col>
      </b-row>

      <!-- ADD GUEST MODAL -->
      <b-modal :id="'add-guest-modal'" title="Afegeix una nou hoste" hide-footer>
        <guest-form @cancel="onAddGuestCancel" @guest-added="onGuestAdded" @add-guest-error="onAddGuestError" />
      </b-modal>

      <!-- VIEW GUEST MODAL -->
      <b-modal :id="'view-guest-modal'" title="Informació de l'hoste" hide-footer>
        <guest-card :guest="selectedGuest" @edit="onEditGuestClicked" @guest-deleted="onGuestDeleted"
          @delete-guest-error="onDeleteGuestError" @guest-confirmed="onGuestConfirmed"
          @confirm-guest-error="onConfirmGuestError" />
      </b-modal>

      <!-- EDIT GUEST MODAL -->
      <b-modal :id="'edit-guest-modal'" title="Edita l'hoste" hide-footer>
        <guest-form :guest="selectedGuest" @cancel="onEditGuestCancel" @guest-edited="onGuestEdited"
          @edit-guest-error="onEditGuestError" />
      </b-modal>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save"> Guarda </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="edit"> Edita </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import GuestForm from "@/views/bookings/booking/components/checkin/GuestForm.vue";
import GuestCard from "@/views/bookings/booking/components/checkin/GuestCard.vue";
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BCardText,
  BListGroup,
  BListGroupItem,
  BAlert,
  BBadge,
  BButton,
  BModal,
} from "bootstrap-vue";
import { getGenderName, notifyError, notifySuccess } from "@/utils/methods";

export default {
  name: "GuestsCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardText,
    BListGroup,
    BListGroupItem,
    BAlert,
    BBadge,
    BButton,
    BModal,
    GuestForm,
    GuestCard,
  },
  data() {
    return {
      editMode: false,
      selectedGuest: null,
      children: null,
      babies: null,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    loadingGuests() {
      return this.$store.getters["booking/loadingGuests"];
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    adultsCount() {
      return this.$store.getters["booking/guestsAdultsCount"];
    },
    childrenCount() {
      return this.$store.getters["booking/guestsChildrenCount"];
    },
    babiesCount() {
      return this.$store.getters["booking/guestsBabiesCount"];
    },
    guestsNumberInconsistency() {
      const bookingAdults = this.booking?.adults || 0;
      const bookingChildren = this.booking?.children || 0;
      const bookingBabies = this.booking?.babies || 0;
      const bookingTotalGuests = bookingAdults + bookingChildren + bookingBabies;

      const checkinAdults = this.adultsCount;
      const checkinChildren = this.childrenCount;
      const checkinBabies = this.babiesCount;
      const checkinTotalGuests = checkinAdults + checkinChildren + checkinBabies;

      if (bookingTotalGuests !== checkinTotalGuests) return true;
      if (bookingAdults !== checkinAdults) return true;
      if (bookingChildren !== checkinChildren) return true;
      if (bookingBabies !== checkinBabies) return true;
      return false;
    },
    canAddClientAsGuest() {
      if (!this.client) {
        return false;
      }

      if (!this.guests.length) {
        return true;
      }

      const guestAlreadyExists = this.guests.some(
        (guest) => guest.idNumber === this.client.idNumber
      );

      return !guestAlreadyExists;
    },
  },
  created() {
    if (this.booking) this.init();
  },
  methods: {
    init() {
      this.children = this.booking.children;
      this.babies = this.booking.babies;
    },
    edit() {
      this.editMode = true;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      setTimeout(() => {
        this.$store
          .dispatch("booking/updateBooking", {
            uuid: this.booking.uuid,
            children: this.children,
            babies: this.babies,
          })
          .then(() => {
            this.editMode = false;
            notifySuccess(
              "Hostes editats",
              "Els hostes ha estat editats correctament"
            );
          })
          .catch(() => {
            notifyError(
              "Error",
              "Hi ha hagut un error al intentar editar els hostes"
            );
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    cancel() {
      this.editMode = false;
      this.init();
    },
    guestSubtitle(guest) {
      if (!guest) return null;

      const text = [];

      if (guest.gender) text.push(getGenderName(guest.gender));
      text.push(`${guest.age} anys`);

      return text.length > 0 ? text.join(", ") : null;
    },
    guestSubtitleTags(guest) {
      if (!guest) return [];

      const tags = [];

      if (guest.gender) tags.push({
        label: getGenderName(guest.gender),
        variant: "primary",
      });
      if (guest.age >= 0 && guest.age <= 3) {
        tags.push({
          label: "Nadó",
          variant: "warning",
        });
      }
      if (guest.age >= 4 && guest.age <= 17) {
        tags.push({
          label: "Nin/na",
          variant: "secondary",
        });
      }
      tags.push({
        label: `${guest.age} anys`,
        variant: "info",
      });

      console.log("guestSubtitleTags", tags);

      return tags;
    },
    showGuestButtonClicked(guest) {
      this.selectedGuest = guest;
      this.$bvModal.show("view-guest-modal");
    },
    newGuestButtonClicked() {
      this.$bvModal.show("add-guest-modal");
    },
    addClientAsGuest() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/addGuest", {
          booking: this.booking["@id"],
          firstName: this.client?.firstName || null,
          lastName: this.client?.lastName || null,
          nationality: this.client?.location?.country || null,
          idType: this.client?.idType || null,
          idNumber: this.client?.idNumber || null,
          confirmed: false,
        })
        .then(() =>
          notifySuccess(
            "Hoste afegit",
            "El client ha estat afegit com a hoste correctament"
          )
        )
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar afegir el client com a hoste"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddGuestCancel() {
      this.$bvModal.hide("add-guest-modal");
    },
    onGuestAdded() {
      this.$bvModal.hide("add-guest-modal");
    },
    onAddGuestError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir l'hoste");
    },
    onEditGuestCancel() {
      this.$bvModal.hide("edit-guest-modal");
    },
    onGuestEdited() {
      this.$bvModal.hide("edit-guest-modal");
    },
    onEditGuestError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar l'hoste");
    },
    onGuestDeleted() {
      this.$bvModal.hide("view-guest-modal");
    },
    onDeleteGuestError() {
      notifyError("Error", "Hi ha hagut un error al intentar eliminar l'hoste");
    },
    onGuestConfirmed() {
      this.$bvModal.hide("view-guest-modal");
    },
    onConfirmGuestError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar confirmar l'hoste"
      );
    },
    onEditGuestClicked(guest) {
      this.selectedGuest = guest;
      this.$bvModal.hide("view-guest-modal");
      this.$bvModal.show("edit-guest-modal");
    },
    onUpdateAdultsNumberClicked() {
      this.$swal({
        title: "Actualitzar adults",
        text: `El nombre d'adults assignats a la reserva passarà de ${this.booking.adults} a ${this.guests.length}, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Accepta",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.updateAdultsNumber();
      });
    },
    updateAdultsNumber() {
      this.$store.dispatch("app/setLoading", true);

      setTimeout(() => {
        this.$store
          .dispatch("booking/updateBooking", {
            uuid: this.booking.uuid,
            adults: this.guests.length,
          })
          .then(() => {
            this.editMode = false;
          })
          .catch(() => {
            notifyError(
              "Error",
              "Hi ha hagut un error al intentar editar els hostes"
            );
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>
